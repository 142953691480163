import {setLocal, getLocal, getQueryHash} from '@/common/js/utils'
import {getProjectInfo,userLogin} from '@/api/user'
import usePage from '@/hooks/usePage'
import to from 'await-to-js';
const useApp = {
  state: () => ({
    platform: null,
    hash: '',
    projectInfo:{
      project_name: '',
      logo: '',
    },
  }),

  getters: {

    getSash(state) {
      return state.hash || getLocal('hash')
    },
    getProjectInfo(state) {
      return state.projectInfo || JSON.parse(getLocal('projectInfo'))
    },
    getPlatform(state) {
      return state.platform || getLocal('platform')
    },
  },
  mutations: {
    SET_HASH(state,queryHash) {
      state.hash  = queryHash;
      setLocal('hash', queryHash)
    },
    SET_PROJECT_INFO(state,projectInfo) {
      state.projectInfo = projectInfo
      setLocal('projectInfo', projectInfo)
    },

    SET_PLATFORM(state,platform) {
      state.platform = platform
      setLocal('platform', platform)
    },

  },
  actions: {
    async getProjectInfo({commit}){
      let [err,res] = await to(getProjectInfo());
      if (res) {
        commit('SET_PROJECT_INFO',res.res)
      }
    },
    async setHash({commit}) {
      let queryHash = getQueryHash();
      if(!!queryHash){
        commit('SET_HASH',queryHash)
      }
    },
    async setPlatform({commit}) {
      const {isMiniProgram} = usePage()
      const isInWxapp = await isMiniProgram()
      if (isInWxapp) {
        commit('SET_PLATFORM','mini')
      }
    },
  },
}


export default useApp
