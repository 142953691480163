import axios from 'axios'
import {Toast} from 'vant'
import router from '../router'
import {setLocal, getLocal, getQueryHash} from '@/common/js/utils'
import aes from '@/utils/aes'
import qs from 'qs';

let baseURL = process.env.VUE_APP_API_URL
let protocol = window.location.protocol; //协议
let host = window.location.host; //主机
let reg = /^localhost+/;
if (reg.test(host)) {
  //若本地项目调试使用
  baseURL = process.env.VUE_APP_API_URL;
} else {
  //动态请求地址   协议 +//+主机
  baseURL = protocol + "//" + host;
}
const fetch = axios.create({
  baseURL: baseURL,
  timeout: 30 * 1000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json;charset=UTF-8',
  },
  withCredentials: true,
})

fetch.interceptors.request.use(
  config => {
    config.data = config.data || {}
    // config.headers = {
    //   ...config.headers,
    // };
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

fetch.interceptors.response.use(res => {
  let {data, status, statusText} = res;
  if (typeof data !== 'object') {
    Toast.fail('请求失败！！')
    return Promise.reject(res)
  }
  const {
    config: {isTransformRes = true}
  } = res
  // 结果不做任何处理
  if (!isTransformRes) return data
  if (data.code !== 0) {
    if (data.msg) Toast(data.msg || '请求失败');
    switch (data.code) {
      case 100:
      case 201:
      case 203:
      case 206:
          setLocal('token', '');
          router.push({path: '/login'})
          break;
      case 202:
      case 204:
          router.push({path: '/error'})
          break;
      case 1007:
        //重复登录
        router.push({path: '/home'})
        break;
    }
    return Promise.reject(data)
  } else if (data.code === 0) {
    return data
  }
})
//获取URL可能携带的hash
let queryHash = getQueryHash();
let baseParams = {
  token: getLocal('token') ? getLocal('token') : "",
  timestamp: Number(new Date().getTime()),
  hash: queryHash || getLocal('hash') || "",
  signType: "SHA1",
}

export function post(url, data = {}) {

  baseParams.token = getLocal('token') ? getLocal('token') : "";
  return new Promise((resolve, reject) => {
    let signParams = {...baseParams, api: url, params: data};
    // console.log('before====',signParams)
    // console.log('after====', aes.Encrypt(signParams))
    fetch({
      method: 'post',
      url: "/api",
      data: signParams,
      headers: {
        "X-Signature": aes.GetSignSha1(signParams)
      }
    })
      .then(res => successHandle(res, resolve))
      .catch(err => {
        errorHandle(err, reject)
      })
  })
}

export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    let signParams = {...baseParams, api: url, params: params};
    fetch({
      method: 'get',
      url: "/api",
      params,
      data: signParams,
      headers: {
        "X-Signature": aes.GetSignSha1(signParams)

      }
    })
      .then(res => successHandle(res, resolve))
      .catch(err => errorHandle(err, reject))
  })
}

// then处理执行了successHandle
function successHandle(res, resolve) {

  if (res.code == 0) {
    resolve(res)
  }
  // } else {
  //   // 弹出toast报错
  //   Toast.fail({
  //     message: res.msg,
  //     duration: 2000
  //   })
  // }
}

// catch处理执行了errorHandle
function errorHandle(err, reject) {
  reject(err)
}

export default fetch
