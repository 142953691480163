import {createStore} from 'vuex'
import VuexPersistence from 'vuex-persist';

import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    app: state.app,
    // project: state.project
  })
});

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})


// 创建 store 容器实例.
const store = createStore({
  state:{
    loading: true
  },
  getters,
  mutations,
  actions,

  modules,
  plugins: [vuexLocal.plugin]
})

export function setupStore(app) {
  app.use(store);
}

export default store