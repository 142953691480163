import EmptyComptent from '@/components/Empty'
import LoadingComptent from '@/components/Loading'
import {
  ActionBar,
  ActionBarIcon,
  ActionBarButton,
  Divider,
  Popup,
  Overlay,
  Loading,
  Dialog,
  ContactCard,
  Form,
  AddressEdit,
  AddressList,
  Field,
  CellGroup,
  Cell,
  SwipeCell,
  Icon,
  Stepper,
  Card,
  Checkbox,
  CheckboxGroup,
  Button,
  Swipe,
  SwipeItem,
  PullRefresh,
  List,
  Tab,
  Tabs,
  SubmitBar,
  Toast,
  Skeleton,
  Picker,
  DatetimePicker,
  Lazyload,
  Popover,
  Search,
  Grid,
  GridItem,
  Image as VanImage,
  Circle,
  Notify,
  CountDown,
  Empty
} from 'vant'

const compList = [
  ActionBar,
  ActionBarIcon,
  ActionBarButton,
  Divider,
  Popup,
  Overlay,
  Loading,
  Dialog,
  ContactCard,
  Form,
  AddressEdit,
  AddressList,
  Field,
  CellGroup,
  Cell,
  SwipeCell,
  Icon,
  Stepper,
  Card,
  Checkbox,
  CheckboxGroup,
  Button,
  Swipe,
  SwipeItem,
  PullRefresh,
  List,
  Tab,
  Tabs,
  SubmitBar,
  Toast,
  Skeleton,
  Picker,
  DatetimePicker,
  Lazyload,
  Popover,
  Search,
  Grid,
  GridItem,
  VanImage,
  Circle,
  Notify,
  CountDown,
  Empty
];

// app
//   .use(ActionBarButton)
//   .use(ActionBarIcon)
//   .use(ActionBar)
//   .use(Divider)
//   .use(Popup)
//   .use(Overlay)
//   .use(Loading)
//   .use(Dialog)
//   .use(Toast)
//   .use(ContactCard)
//   .use(Form)
//   .use(AddressEdit)
//   .use(AddressList)
//   .use(Field)
//   .use(CellGroup)
//   .use(Cell)
//   .use(SwipeCell)
//   .use(Icon)
//   .use(Stepper)
//   .use(Card)
//   .use(Button)
//   .use(Swipe)
//   .use(SwipeItem)
//   .use(PullRefresh)
//   .use(List)
//   .use(Tab)
//   .use(Tabs)
//   .use(SubmitBar)
//   .use(Checkbox)
//   .use(CheckboxGroup)
//   .use(Skeleton)
//   .use(Picker)
//   .use(DatetimePicker)
//   .use(Lazyload)
//   .use(Popover)
//   .use(Search)
//   .use(Grid)
//   .use(GridItem)
//   .use(VanImage)
//   .use(Circle)
//   .use(Notify)
//   .use(CountDown)
//   .use(Empty)



export function registerGlobComponent(app) {
  compList.forEach((comp) => {
    // app.component(comp.name || comp, comp);
    app.use(comp)
  });
  app.component('Empty', EmptyComptent)
  app.component('Loading', LoadingComptent)
  app.use(Empty)
}