<template>
  <div class="custom-emptybox" v-if="show">
    <van-empty
      class="custom-empty"
      :image="url || $config.defaultEmpty"
      :description="text"
      v-if="image"
    >
    <slot></slot>
    </van-empty>
    <div v-else>{{text}}</div>
  </div>
</template>
<script>
export default {
  components: {
  },
  props: {
    text: {
      type:String,
      default:'暂无数据'
    },
    image: {
      type:Boolean,
      default:true
    },
    url:{
      type:String,
      default:''
    },
    show: {
      type:Boolean,
      default:false
    }
  },
  setup(props, { emit }) {
    return {
    }
  }
}
</script>
<style lang="less" scoped>
  .custom-emptybox{
    text-align: center;
    padding: 20px 0;
    .custom-empty {
     &:deep(.van-empty__image) {
        // width: 140px;
        // height: 140px;
      }
    }
  }
</style>
