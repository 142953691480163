const CryptoJS = require('crypto-js') // 引用AES源码js
const Qs = require('qs')
const key = CryptoJS.enc.Utf8.parse('addf850ca521529d811b2daa76ac2ce1') // 十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse('XDSX123412341234') // 十六位十六进制数作为密钥偏移量

// 解密方法
function Decrypt(word) {
  let encryptedHexStr = CryptoJS.enc.Hex.parse(word)
  let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr)
  let decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr.toString()
}

// 加密方法
function Encrypt(word) {
  let srcs = CryptoJS.enc.Utf8.parse(word)
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.ciphertext.toString().toUpperCase()
}

function DecryptQuery() {
  const [url, str] = window.location.href.split('?')
  if (!str) return { url }
  const { secret } = Qs.parse(str)
  if (!secret) return { url }
  let encryptedHexStr = CryptoJS.enc.Hex.parse(secret)
  let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr)
  let decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return { url, secret: Qs.parse(decryptedStr.toString()) }
}

function GetSignSha1(params){
  let init =  {
    "timestamp": new Date().getTime(),
    "signType": "SHA1"
  }
  let newParams = Object.assign({},init, params);   // init 函数内部的对象  obj  外部传进来的对象  得到一个新的对象
  let pro = [];  //声明一个空数组
  let i = 0;
  for (let key in newParams) {  //取出对象里面的键  添加到数组中
    pro[i] = key;
    i++;
  }
  pro.sort();   //把数组里面进行排序
  let string  = ''
  let j = 0
  for (let tmp in newParams) {   // 根据排序好的进行重新赋值
    if(pro[j] =='params'){
      newParams[pro[j]] = JSON.stringify(newParams[pro[j]])
    }
    string+= `${pro[j]}=${newParams[pro[j]]}${j<pro.length -1?'|':''}`;
    j++;
  }
  var wordArray = CryptoJS.HmacSHA1(string, key);
  var str = wordArray.toString(CryptoJS.enc.Hex);
  return str.toUpperCase()
}

export default {
  Decrypt,
  Encrypt,
  DecryptQuery,
  GetSignSha1
}