import {post,get} from '../utils/axios'

export function getUserInfo() {
  return axios.post('/login/apiLoginAh5Service/getInfo')
}

export function EditUserInfo(params) {
  return axios.put('/user/info', params)
}

export function logout() {
  return axios.post('/user/logout')
}

// 设置提现密码短信验证码
export function sendSetPayPasswordVerifyCode(params) {
  return axios.post('/user/apiUserAh5Service/sendSetPayPasswordVerifyCode', params, { isTransformRes: false })
}
// 设置提现密码 /dsx_user/apiUserAh5Service/setPayPassword
export function setPayPassword(params) {
  return axios.post('/user/apiUserAh5Service/setPayPassword', params, { isTransformRes: false })
}
// 验证提现密码 /dsx_user/apiUserAh5Service/checkPayPassword
export function checkPayPassword(params) {
  return axios.post('/user/apiUserAh5Service/checkPayPassword', params, { isTransformRes: false })
}
// 退出登录
export function userLoginOut(params) {
  return post('user/logout', params)
}
// 登录
export function userLogin(params) {
  return post('user/login', params)
}
// 我的信息
export function getMineInfo(params) {
  return post('user/mine', params)
}

// 项目方信息
export function getProjectInfo(params) {
  return post('base/getInfo', params)
}

// 我的推荐人
export function getSuperiorInfo(params) {
  return post('user/info', params)
}
// 获取验证码
export function sendSms(params) {
  return post('user/sendSms', params)
}
// 修改密码
export function changePassword(params) {
  return post('user/changePassword', params)
}