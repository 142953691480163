import {setLocal, getLocal} from '@/common/js/utils'
import {userLogin,userLoginOut} from '@/api/user'
import router from '@/router'
import to from 'await-to-js';

const useUser = {
  state: () => ({
    token: '',
    userInfo: {},
  }),

  getters: {
    getTokenState(state) {
      return state.token || getLocal('token')
    },
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      setLocal('token', token)
    },
  },
  actions: {
    async userLogin({commit},params){
      let [err,res] = await to(userLogin(params));
      if (res) {
        commit('SET_TOKEN',res.res.token);
        // return res
      } else{
        // return err
      }
      return [err,res]
    },
    async userLoginOut({commit}){
      let [error,result] = await to(userLoginOut());
      if(error) return;
      let {code, res} = result;
      if (code == 0) {
        commit('SET_TOKEN','')
        window.location.href = '/login'
        // router.push('/login')
      }
    },
  },
}


export default useUser
