<template>
	<!-- <div class="spinner-box" style="z-index: 10000;">
		<div class="spinner">
				  <div class="double-bounce1"></div>
				  <div class="double-bounce2"></div>
				</div>
	</div> -->
   <div class="loadingBox">
    <div class="loadingBox-cont">
      <div class="loadingBox-contmoveEle" style="border-top-color:#DABA88"></div>
      <img class="loadingBox-contmoveEle_img" :src="loadingUrl"/>
    </div>
  </div>
</template>

<script>
import { computed} from 'vue'
  import { useStore } from 'vuex'
export default {
  setup(){
    const store = useStore()
    const loadingUrl = computed(()=>{
      return store.getters.projectInfo?.loading_image
    })
    return {
      loadingUrl
    }

  }

}
</script>

<style scoped>
/* .spinner-box{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
	.loading-model{
		background: rgba(255, 255, 255, 0.6);
		z-index: 1000;
	}
	.spinner {
	  width: 60px;
	  height: 60px;

	  position: relative;
	  margin: 300upx auto;
	  z-index: 1000;
	}

	.double-bounce1, .double-bounce2 {
	  width: 100%;
	  height: 100%;
	  border-radius: 50%;
	  background-color: #0070D0;
	  opacity: 0.6;
	  position: absolute;
	  top: 0;
	  left: 0;
	  animation: bounce 2.0s infinite ease-in-out;
	  z-index: 1000;
	}

	.double-bounce2 {
	  animation-delay: -1.0s;
	}


	@keyframes bounce {
	  0%, 100% {
	    transform: scale(0.0);
	  } 50% {
	    transform: scale(1.0);
	  }
	} */
.loadingBox{
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background: #fff;
  z-index: 2021;
}

.loadingBox-cont{
  width: 85px;
  height: 85px;
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%)  scale(0.64);
  border-radius: 50%;
  background: #fff;
}

.loadingBox-contmoveEle{
  width:85px;
  height:85px;
  box-sizing: border-box;
  border-radius: 50%;
  border-top: 3px solid #29b6d5;
  border-right:3px solid transparent;
  animation: circleRoate 1.3s linear infinite;
}
.loadingBox-contmoveEle_img{
  position: absolute;
  z-index:2;
  width:60px;
  height:60px;
  border-radius: 50%;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
}

@keyframes circleRoate {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}
@-webkit-keyframes circleRoate {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}
</style>
