import { createRouter, createWebHistory } from 'vue-router'
const router = createRouter({
  history: createWebHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  scrollBehavior (to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/home',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
      meta: {
        index: 1,
        title: '代理中心',
        keepAlive: true,
        showLoading:true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
      meta: {
        index: 1,
        title: '登录',
        showLoading:false
      }
    },
    {
      path: '/wallet',
      name: 'WALLET',
      component: () => import(/* webpackChunkName: "WALLET" */ '@/views/wallet/index.vue'),
      meta: {
        index: 2,
        title: '我的钱包',
        showLoading:true
      }
    },
    {
      path: '/walletDetail',
      name: 'WALLETDETAIL',
      component: () => import(/* webpackChunkName: "WALLETDETAIL" */ '@/views/wallet/detail.vue'),
      meta: {
        index: 3,
        title: '钱包明细',
        showLoading:true
      }
    },
    {
      path: '/income',
      name: 'INCOME',
      component: () => import(/* webpackChunkName: "INCOME" */ '@/views/income/index.vue'),
      meta: {
        index: 2,
        title: '我的收入',
        showLoading:true
      }
    },
    {
      path: '/incomeDetail',
      name: 'INCOMEETAIL',
      component: () => import(/* webpackChunkName: "INCOMEETAIL" */ '@/views/income/detail.vue'),
      meta: {
        index: 3,
        title: '收入明细',
        showLoading:true
      }
    },
    {
      path: '/statis',
      name: 'STATIS',
      component: () => import(/* webpackChunkName: "STATIS" */ '@/views/statis/index.vue'),
      meta: {
        index: 2,
        title: '我的业绩',
        showLoading:true
      }
    },
    {
      path: '/fans',
      name: 'FANS',
      component: () => import(/* webpackChunkName: "FANS" */ '@/views/fans/index.vue'),
      meta: {
        index: 2,
        title: '我的粉丝',
        showLoading:true
      }
    },
    {
      path: '/fansMore',
      name: 'FANSMORE',
      component: () => import(/* webpackChunkName: "FANSMORE" */ '@/views/fans/more.vue'),
      meta: {
        index: 3,
        title: '更多推荐',
        showLoading:true
      }
    },
    {
      path: '/fansDetail',
      name: 'FANSDETAIL',
      component: () => import(/* webpackChunkName: "FANSDETAIL" */ '@/views/fans/detail.vue'),
      meta: {
        index: 4,
        title: '团长详情',
        showLoading:true
      }
    },
    {
      path: '/team',
      name: 'TEAM',
      component: () => import(/* webpackChunkName: "TEAM" */ '@/views/team/index.vue'),
      meta: {
        index: 2,
        title: '我的团队',
        showLoading:true
      }
    },
    {
      path: '/teamDetail',
      name: 'TEAMDETAIL',
      component: () => import(/* webpackChunkName: "TEAMDETAIL" */ '@/views/team/detail.vue'),
      meta: {
        index: 3,
        title: '我的团队明细',
        showLoading:true
      }
    },
    {
      path: '/info',
      name: 'INFO',
      component: () => import(/* webpackChunkName: "INFO" */ '@/views/set/info.vue'),
      meta: {
        index: 2,
        title: '账户设置',
        showLoading:true
      }
    },
    {
      path: '/password',
      name: 'PASSWORD',
      component: () => import(/* webpackChunkName: "PASSWORD" */ '@/views/set/password.vue'),
      meta: {
        index: 2,
        title: '找回密码',
        showLoading:false
      }
    },
    {
      path: '/rank',
      name: 'RANK',
      component: () => import(/* webpackChunkName: "RANK" */ '@/views/rank/index.vue'),
      meta: {
        index: 2,
        title: '排行榜',
        showLoading:false
      }
    },
    {
      path: '/selectGoods',
      name: 'SELECTGOODS',
      component: () => import(/* webpackChunkName: "SELECTGOODS" */ '@/views/rank/selectGoods.vue'),
      meta: {
        index: 3,
        title: '选择商品',
        showLoading:false
      }
    },
    {
      path: '/tips',
      name: 'TIPS',
      component: () => import(/* webpackChunkName: "TIPS" */ '@/views/set/tips.vue'),
      meta: {
        index: 3,
        title: '收不到验证码？',
        showLoading:false
      }
    },
    {
      path: '/pay',
      name: 'Pay',
      component: () => import(/* webpackChunkName: "TIPS" */ '@/views/pay/index.vue'),
      meta: {
        index: 3,
        title: '支付订单',
        showLoading:false
      }
    },
    {
      path: '/pay-result',
      name: 'payResult',
      component: () => import(/* webpackChunkName: "TIPS" */ '@/views/pay/result.vue'),
      meta: {
        index: 3,
        title: '支付结果',
        showLoading:false
      }
    },
    {
      path: '/pay-error',
      name: 'payError',
      component: () => import(/* webpackChunkName: "TIPS" */ '@/views/pay/error.vue'),
      meta: {
        index: 3,
        title: '支付失败',
        showLoading:false
      }
    },
    {
      path: "/error",
      name: 'error',
      component: () => import(/* webpackChunkName: "ERROR" */ '@/views/error/error.vue'),
      meta: {
        index: 1,
        title: '访问链接错误!',
        showLoading:false
      }
    },
    {
      path: "/:catchAll(.*)",
      name: '加载中...',
      redirect: '/login'
    },
  ]
})

export function setupRouter(app) {
  app.use(router);
}

export default router
