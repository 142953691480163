// import { ref } from 'vue'
import wx from 'weixin-js-sdk'
import router from '../router'
import { WxPage } from '../enum'
import { getLocal, getUrlQuery } from '@/common/js/utils'
import Qs from 'qs'

export default () => {
  // 获取环境状态
  const isMiniProgram = () => {
    return new Promise(resolve => {
      let ua = navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        wx.miniProgram.getEnv(res => {
          if (res.miniprogram) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
      } else {
        resolve(false)
      }
    })
  }
  const isInWxapp = getLocal('platform') === 'mini'
  const navigateTo = url => {
    const wxurl = `${WxPage.copy}?url=${encodeURIComponent(url)}`
    isInWxapp && wx.miniProgram.navigateTo({ url })
    isInWxapp && wx.miniProgram.postMessage({ data: { url } })
    !isInWxapp && router.push(url)
  }
  const switchTab = url => {
    const wxurl = `${WxPage.copy}?url=${encodeURIComponent(url)}`
    isInWxapp && wx.miniProgram.navigateTo({ url: wxurl })
    isInWxapp && wx.miniProgram.postMessage({ data: { url } })
    !isInWxapp && router.push(url)
  }
  const switchTabWx = url => {
    isInWxapp && wx.miniProgram.switchTab({ url })
    isInWxapp && wx.miniProgram.postMessage({ data: { url } })
  }
  const redirectTo = (url,options = {}) => {
    const wxurl = `${WxPage.copy}?url=${encodeURIComponent(url)}`
    isInWxapp && wx.miniProgram.redirectTo({ url })
    isInWxapp && wx.miniProgram.postMessage({ data: { url,...options } })
    !isInWxapp && router.push({ path: url, query: Qs.parse(getUrlQuery(url)), replace: true })
  }

  const reLaunch = (url,options = {}) => {
    isInWxapp && wx.miniProgram.reLaunch({ url })
  }
  const navigateBack = () => {
    isInWxapp && wx.miniProgram.navigateBack()
    !isInWxapp && router.go(-1)
  }
  const redirectToHome = (url = '/home') => {
    isInWxapp && wx.miniProgram.redirectTo({ url })
    isInWxapp && wx.miniProgram.postMessage({ data: { url } })
    !isInWxapp && router.replace(url)
  }

  const getFixHeight = () => {
    return isInWxapp ? { height: '100vh' } : { height: 'calc(100vh - 44px)' }
  }

  return {
    isMiniProgram,
    navigateTo,
    switchTab,
    redirectTo,
    reLaunch,
    navigateBack,
    getFixHeight,
    switchTabWx
  }
}
