<template>
  <router-view class="router-view" v-slot="{ Component }">
    <transition :name="transitionName" mode="out-in">
      <keep-alive :include="cacheView">
          <component :is="Component" />
      </keep-alive>
    </transition>
  </router-view>
  <!-- 加载动画 -->
  <template v-if="$route.meta?.showLoading">
    <Loading v-if="appLoading" ></Loading>
  </template>
</template>

<script>
  import {onMounted, reactive, toRefs, provide,computed} from 'vue'
  import {useRouter,useRoute } from 'vue-router'
  // import wx from 'weixin-js-sdk'
  import {debounce, throttle, deepClone} from '@/common/js/utils'
  import {setLocal, getLocal, getQueryString} from '@/common/js/utils'
  import {Toast} from 'vant'
  import {useStore} from 'vuex'
  import Loading from './components/Loading'
  // 银行支付白名单
  const whiteUrl = ['/pay','/pay-result','/pay-error','/error']
  export default {
    setup(props, context) {
      //注入部分配置/函数取代  globalProperties
      provide('debounce', debounce);
      provide('throttle', throttle);
      provide('deepClone', deepClone);
      provide('appLoadFanish', ()=>{
        store.commit('loadStatus', false)
      });

      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const state = reactive({
        cacheView:['home'],
        transitionName: 'slide-left',
      })

      router.beforeEach(async (to, from, next) => {
        if(to.meta.title){
          document.title = to.meta.title
        }
        // wx.miniProgram.postMessage({ data: { to: to.path, form: from.path } })
        // let token = store.getters.token;
        store.dispatch('setHash')
        store.dispatch('setPlatform')
        if (to.meta.index > from.meta.index) {
          state.transitionName = 'slide-left' // 向左滑动
        } else if (to.meta.index < from.meta.index) {
          // 由次级到主级
          state.transitionName = 'slide-right'
        } else {
          state.transitionName = '' // 同级无过渡效果
        }
        // if (!whiteUrl.includes(to.path)) {
        //   if(!store.getters.projectInfo.project_name){
        //     await store.dispatch('getProjectInfo')
        //   }
        // }
        // 暂时不用
        store.commit('loadStatus', true)
        next()

      })
      router.afterEach(function (to) {
        // 暂时不用
        // store.commit('loadStatus', false)

      })
      // const appLoadFanish = () => {
      //   store.commit('loadStatus', false)
      // }

      onMounted(async () => {
        if(!whiteUrl.includes(window.location.pathname)){
          await store.dispatch('getProjectInfo')
        }
      })
      let appLoading  = computed( () => store.state.loading )
      return {
        ...toRefs(state),
        appLoading
      }
    }
  }
</script>

<style lang="less">
  html,
  body {
    height: 100%;
    margin: 0 auto;
    overflow-x: hidden;
    overflow-y: auto;
    background: #f8f8f8;
  }

  * {
    -webkit-overflow-scrolling: touch;
  }

  #app {
    height: 100%;
    max-width: 375px;
    min-width: 375px;
    margin: 0 auto;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000;
    background: #f8f8f8;
  }

  .router-view {
    height: auto;
    width: 375px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    -webkit-overflow-scrolling: touch;
  }

  .slide-right-enter-active,
  .slide-right-leave-active,
  .slide-left-enter-active,
  .slide-left-leave-active {
    height: 100%;
    will-change: transform;
    transition: all 500ms;
    position: absolute;
    backface-visibility: hidden;
    opacity: 1;
  }

  .slide-right-enter {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  .slide-right-leave-active {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  .slide-left-enter {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  .slide-left-leave-active {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  .van-badge--fixed {
    z-index: 1000;
  }
</style>
