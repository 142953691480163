import './common/style/base/base.less'
import 'lib-flexible/flexible'
import 'vant/lib/index.css' // 全局引入样式

import {createApp} from 'vue'
import App from './App.vue'
import router, {setupRouter} from './router'
import {setupStore} from './store'
import { registerGlobComponent } from './hooks/registerGlobComponent';
import config from './utils/config'

const app = createApp(App)

// if (process.env.NODE_ENV != 'production') {
//   // const vConsole1 = new Vconsole()
//   // app.use(vConsole1)
// }

// 全局过滤器
app.config.globalProperties.$filters = {
  prefix(url) {
    if (url && url.startsWith('http')) {
      return url
    } else {
      url = `https://x.jzbull.com${url}`
      return url
    }
  }
}
app.config.globalProperties.$config = config

// Configure registerGlobComponent
registerGlobComponent(app);

// Configure vuex store
setupStore(app);
// Configure routing
setupRouter(app);
// process.env.VUE_APP_MOCK && require('../mock/index')

// Mount when the route is ready
// router.isReady().then(() => {
  app.mount('#app', true);
// });

